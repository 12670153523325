.footer-right__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;

  .content__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;

    a {
      &:hover {
        color: #ee4d2d
      }
    }
  }
}

@media (max-width: 850px) {
  .footer-right__content {
    align-items: center;

    .content__links {
      align-items: center;
    }
  }
}
@primary-color: rgb(238, 77, 45);