.navbar-hamburger__content {
    .content {
        &__background {
            background: rgba(0, 0, 0, .5);
            z-index: 98;
            position: absolute;
            padding: 0;
            margin: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            top: 64px;
            overflow-x: hidden;
        }

        &__checkbox {
            transition: all .3s;
            box-sizing: border-box;
            display: none;

            &:checked {
                &~.content__icon {
                    &>.horizontal,
                    &>.diagonal.part-1,
                    &>.diagonal.part-2 {
                        transition: all .3s;  
                        box-sizing: border-box;
                    }
    
                    &>.horizontal {
                        opacity: 0
                    }
    
                    &>.diagonal.part-1 {
                        transform: rotate(135deg);
                        margin-top: 7px
                    }
    
                    &>.diagonal.part-2 {
                        transform: rotate(-135deg);
                        margin-top: -7px
                    }
                }
            }
        }

        &__icon {
            transition: all .3s;
            box-sizing: border-box;
            cursor: pointer;
            position: absolute;
            top: 24px;
            right: 24px;
            height: 16px;
            width: 20px;
            aspect-ratio: 1;

            .spinner {
                transition: all .3s;
                box-sizing: border-box;
                position: absolute;
                height: 2px;
                width: 100%;
                background-color: #000;

                &.horizontal, 
                &.diagonal.part-1,
                &.diagonal.part-2 {
                    position: relative;
                    box-sizing: border-box;
                    transition: all .3s;
                    float: left;
                    
                }
                &.horizontal, &.diagonal.part-2 {
                    margin-top: 3px
                }        
            }
        }
    }

    &:has(.content__checkbox:checked) {
        &~.content__mobile {
            transform: translateX(0)
        }
    }
}
@primary-color: rgb(238, 77, 45);