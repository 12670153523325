.navbar-left__content {
    display: flex;
    align-items: center;
    gap: 1rem;
    
    .logo {
        max-width: 13rem;
        max-height: 2rem;
    }
}

@media (max-width: 1000px) {
    .navbar-left__content {
        .logo {
            max-height: 1.5rem;
        }
    }
}
@primary-color: rgb(238, 77, 45);