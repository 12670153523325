@import '../../Theme/colors.less';

.navbar__container {
    width: 100%;
    min-height: 64px;

    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 10;
    background-color: @background__primary;
    border-bottom: 1px solid @grey__border;

    .navbar__content {
        position: relative;
        width: 100%;
        min-height: 64px;

        display: flex;
        justify-content: center;
        position: fixed;

        .content__wrapper {
            padding: 1rem 2rem;

            width: 100%;
            max-width: 1440px;
            min-height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                text-decoration: none;
                
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .navbar__container {
        .navbar__content {
            .content__wrapper {
                padding: 1rem 1.5rem;
                justify-content: flex-start;
            }
        }
    }
}
@primary-color: rgb(238, 77, 45);