.footer-left__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .content {
    &__social-media, &__downloads {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .social-media, .downloads {
        &__links {
          display: flex;
          align-items: center;
          gap: 1rem;
          
          img {
            height: 100%;
            max-height: 3rem;
            cursor: pointer;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }    
}

@media (max-width: 850px) {
  .footer-left__content {
    align-items: center;
    gap: 2rem;

    .content {
      &__social-media, &__downloads {
        align-items: center;
      }
    }
  }
}

@media (max-width: 550px) {
  .footer-left__content {
    .content__downloads {
      .downloads__links {
        flex-direction: column;

        img {
          width: 100%;
          max-width: 10rem;
          max-height: none;
        }
      }
    } 
  }
}


@primary-color: rgb(238, 77, 45);