@import '../../../../Theme/colors.less';

.navbar-right__content {
    display: flex;
    align-items: center;

    .content {
        &__desktop,
        &__mobile {
            .link__label {
                display: flex;
                align-items: center;
                gap: 0.25rem;
    
                padding: 0.25rem;
                font-size: 14px;
                line-height: 24px;
                color: #202325;
                width: fit-content;
                cursor: pointer;
                white-space: nowrap;
    
                svg {
                    width: 1.2rem;
                    transition: transform 0.3s ease-in-out;
                }
    
                &.active, &:hover {
                    color: @branding__primary;
                    
                    svg {
                        stroke: @branding__primary;
                    }
                }
    
                &.open {
                    svg {
                        transform: rotate(180deg);
                        stroke: @branding__primary;
                    }
                }
            }

            .link__wrapper {
                display: flex;

                .link__children {
                    display: flex;
                    z-index: 5;

                    visibility: hidden;
                    opacity: 0;
                    height: 0;
                    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, height 0.2s ease-in-out;
                    
                    &.open {
                        visibility: visible;
                        opacity: 1;
                        height: auto;
                        // transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, height 0.2s ease-in-out;
                    }
                }
            }
        }

        &__desktop {
            display: flex;
            align-items: center;
            gap: 1rem;

            .link__wrapper {
                display: flex;
                align-items: center;

                .link__children {
                    position: absolute;
    
                    top: 48px;
                    left: 0px;
    
                    width: 100%;
                    min-height: 64px;
                    justify-content: center;
                    align-items: center;
    
                    background-color: @background__primary;
                    border-bottom: 1px solid @grey__border;
    
                    .children__content {
                        width: 100%;
                        max-width: 1440px;
                        padding: 1rem 2rem;
    
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 1.75rem;
                    }
                }
            }
        }

        &__mobile {
            display: none;
            position: absolute;
            
            top: 64px;
            right: 0;
            width: 250px;
            height: 100vh;

            background: @background__primary;
            z-index: 100;

            transform: translateX(250px);
            transition: transform 250ms ease-in-out;

            .content__sidebar {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 1rem;

                padding: 1rem;

                .link__wrapper {
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;

                    .children__content {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 1rem;

                        padding-left: 1rem;
                        padding-top: 0;
                        transition: padding-top 0.2s ease-in-out;
                        
                        &.open {
                            padding-top: 1rem;
                            transition: padding-top 0.2s ease-in-out;
                        }
                    }

                    &:has(.open).link__wrapper {
                        gap: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .navbar-right__content {
        .content {
            &__desktop {
                display: none;
            }

            &__mobile {
                display: inline-block;
            }
        }
    }
}
@primary-color: rgb(238, 77, 45);