.footer__container {
  width: 100%;
  background-color: #dfdfdf;

  .footer__content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1440px;
    margin: auto;

    .content {
      &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
      }
    }
  }
}

@media (max-width: 850px) {
  .footer__container {
    .footer__content {
      .content {
        &__top {
          flex-direction: column;
        }
      }
    }
  }
}

@primary-color: rgb(238, 77, 45);