:root {
  --black: rgba(0, 0, 0, 0.85);
  --grey: rgba(32, 35, 37, 0.6);
}

p, a, i, div {
  font-family: "Roboto Regular";
  color: var(--grey);
  font-size: 1rem;
  margin-bottom: 0;
}

a {
  text-decoration: underline;
  
  &:hover {
    text-decoration: underline;
    color: #ee4d2d
  }
}

h1, h3 {
  font-family: "Shopee_font Bold";
  font-weight: 700;
  text-align: center;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.8rem;
  line-height: 2rem;
}

h3 {
  font-size: 1rem;
  margin-bottom: 0;
}

// Button Primary
button.btn-primary {
  color: #FFFFFF;
  background-color: #010089;
  font-size: 1.15rem;
  height: 4rem;
  border: none;
  border-radius: 4rem;
  cursor: pointer;
  padding: 0 3rem;
  margin: 2rem;
  
  &:hover {
    opacity: 0.7;
  }
}

@primary-color: rgb(238, 77, 45);