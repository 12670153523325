.footer-bottom__content {
  width: 100%;
  
  * {
    text-align: center;
    font-size: 0.75rem;
  }
  a {
    text-decoration: underline;
  }
}
@primary-color: rgb(238, 77, 45);